<template>
  <v-container class="pa-10 mb-5" >
    <v-row justify="center">
      <v-card class="px-10 pt-2" max-width="1000px">
        <v-card-title class="mb-2">
          <span class="headline">Create group</span>
        </v-card-title>
        <v-card-text style="background:#E1E1E1; width:100%;" > 
          <v-form v-model="valid" class="pa-5" >
            <v-row>
             <v-col sm="6" md="5" class="d-flex flex-row">
                <v-select
                  v-model="selectedCatyegory"
                  :items="category"
                  :rules="[(v) => !!v || 'category is required']"
                  required
                  label="category"
                  outlined
                  @change="onSelectCategory"
                  dense
                ></v-select>

                <v-btn
                  color="#854FFF"
                  fab
                  small
                  dark
                  class="ml-2"
                  @click="dialog1 = true"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>

              <v-spacer> </v-spacer>

              <v-col sm="6" md="5" class="pl-3">
                <v-text-field
                  v-model="groupTitle"
                  label="Group Title"
                  outlined
                  dense
                  required
                  :rules="[(v) => !!v || 'Product Title is required']"
                ></v-text-field>
              </v-col>

              <v-col sm="6" md="5" class="d-flex flex-row">

                <v-select
                  v-model="selectedSubCatyegory"
                  :items="sub_category"
                  :rules="[(v) => !!v || 'category is required']"
                  required
                  label="Sub category"
                  outlined
                  @change="onSelectSubCategory"
                  dense
                ></v-select>

                <v-btn
                  color="#854FFF"
                  fab
                  small
                  dark
                  class="ml-2"
                  @click="dialog2 = true"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>

              <v-spacer> </v-spacer>

              <v-col sm="6" md="5">
                <v-text-field
                  v-model="flashsellname"
                  label="Flash Sale"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col sm="6" md="5" class="d-flex flex-row">
                <v-select
                  v-model="selectedSubSubCategory"
                  :items="sub_sub_category"
                  item-text="title"
                  label="Sub Sub category"
                  outlined
                  dense
                ></v-select>
                <v-btn
                  color="#854FFF"
                  fab
                  small
                  dark
                  class="ml-2"
                  @click="dialog3 = true"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>

              <v-spacer> </v-spacer>
              <v-col cols="12" sm="6" md="5">
                <v-text-field
                  v-model="price"
                  label="Group Product Total Price"
                  outlined
                  dense
                  required
                  :rules="[(v) => !!v || 'Group Product Title is required']"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="5">
                <v-text-field
                  v-model="flashsellname"
                  label="Flashsell Name"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="5">
                <v-datetime-picker
                  label="Group Product Start Date"
                  v-model="startdate"
                ></v-datetime-picker>
              </v-col>
              <v-col cols="12" sm="6" md="5">
                <v-datetime-picker
                  label="Group Product End Date"
                  v-model="enddate"
                ></v-datetime-picker>
              </v-col>

              <v-col cols="12" sm="6" md="5">
                <v-textarea
                  v-model="description"
                  label="Product Description"
                  outlined
                  dense
                  required
                  :rules="[(v) => !!v || 'Description is required']"
                ></v-textarea>
              </v-col>

              <v-col cols="12" sm="6" md="5">
                <v-text-field
                  v-model="price"
                  label="Product Price"
                  required
                  :rules="[(v) => !!v || 'Price is required']"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="5">
                <v-text-field
                  v-model="point"
                  label="Product Point"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="5">
                <v-datetime-picker
                  label="Product Point Start Date"
                  v-model="point_start_date"
                ></v-datetime-picker>
              </v-col>

              <v-col cols="12" sm="6" md="5">
                <v-datetime-picker
                  label="Product Point End Date"
                  v-model="point_end_date"
                ></v-datetime-picker>
              </v-col>
              <v-col cols="12" sm="6" md="5">
                <v-text-field
                  v-model="discount_type"
                  label="Discount Type"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="5">
                <v-text-field
                  v-model="amount"
                  label="Discount Amount "
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="5">
                <v-datetime-picker
                  label="Discount Start Date"
                  v-model="discount_start_date"
                ></v-datetime-picker>
              </v-col>
              <v-col cols="12" sm="6" md="5">
                <v-datetime-picker
                  label="Discount End Date"
                  v-model="discount_end_date"
                ></v-datetime-picker>
              </v-col>

              <v-col cols="12" sm="6" md="5">
                <v-file-input
                  color="deep-purple accent-4"
                  counter
                  label="File input"
                  multiple
                  placeholder="Select your files"
                  prepend-icon="mdi-paperclip"
                  outlined
                  :show-size="1000"
                  v-model="image"
                  :rules="[(value) => value.length != 0 || 'Image is required']"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip
                      v-if="index < 2"
                      color="deep-purple accent-4"
                      dark
                      label
                      small
                      >{{ text }}</v-chip
                    >

                    <span
                      v-else-if="index === 2"
                      class="overline grey--text text--darken-3 mx-2"
                      >+{{ image.length - 2 }} File(s)</span
                    >
                  </template>
                </v-file-input>
              </v-col>

              <v-col cols="12" sm="6" md="2">
                <v-text-field
                  v-for="(feature, index) in key_features"
                  :key="index"
                  v-model="key_features[index]"
                  label="Product key features"
                  outlined
                  dense
                ></v-text-field>
                <v-btn @click="addFeatures" color="success">Add Feature</v-btn>
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <v-text-field
                  v-for="(pides, index) in products_ids"
                  :key="index"
                  v-model="products_ids[index]"
                  label="Group Products Ids"
                  outlined
                  dense
                ></v-text-field>
                <v-btn @click="addPIDS" color="success">Add Product IDS</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" @click="close">Cancel</v-btn>
          <v-btn color="blue darken-1" @click="save" :disabled="!valid"
            >Create Group</v-btn
          >
        </v-card-actions>

        <v-dialog v-model="dialog1" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">Add Category</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Category"
                      v-model="new_category"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="orange" tile small @click="dialog1 = false"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" tile small @click="saveCategory"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialog2" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">sub category</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Sub Category"
                      v-model="new_sub_category"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="orange" tile small @click="dialog2 = false"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" tile small @click="addSubCategory"
                >Add To List</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialog3" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">Sub Sub Category</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Sub Sub Category"
                      v-model="new_sub_sub_category"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="orange" @click="dialog3 = false" small tile
                >Close</v-btn
              >
              <v-btn color="blue darken-1" @click="addSubSubCategory" small tile
                >Add to list</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    valid: false,

    dialog1: false,
    new_category: "",

    dialog2: false,
    new_sub_category: "",

    dialog3: false,
    new_sub_sub_category: "",

    category: [],
    selectedCatyegory: "",

    sub_category: [],
    selectedSubCatyegory: "",

    sub_sub_category: [],
    selectedSubSubCategory: "",

    groupTitle: "",
    flashsellname: "",
    image: [],
    description: "",
    key_features: [],
    products_ids: [],
    quantity: "",
    properties: true,
    point: "",
    price: "",
    discount_type: "",
    amount: "",
    start_date: "",
    end_date: "",
    discount_end_date: "",
    discount_start_date: "",
    point_end_date: "",
    point_start_date: "",
    end_date: "",
    order: "",
  }),

  methods: {
    addFeatures() {
      this.key_features.push("");
    },
    addPIDS() {
      this.products_ids.push("");
    },
    save() {
      let formData = new FormData();

      formData.append("category", this.selectedCatyegory);
      formData.append("sub_category", this.selectedSubCatyegory);
      formData.append("sub_sub_category", this.selectedSubSubCategory);
      formData.append("groupTitle", this.groupTitle);
      formData.append("flashsellname", this.flashsellname);
      formData.append("startdate", this.startdate);
      formData.append("enddate", this.enddate);
      formData.append("description", this.description);
      formData.append("key_features", JSON.stringify(this.key_features));
      formData.append("products_ids", JSON.stringify(this.products_ids));
      formData.append("quantity", this.quantity);
      formData.append("price", this.price);
      formData.append("amount", this.amount);

      formData.append("discount_end_date", this.discount_end_date);
      formData.append("discount_start_date", this.discount_start_date);
      formData.append("point_start_date", this.point_start_date);
      formData.append("point_end_date", this.point_end_date);

      for (var i = 0; i < this.image.length; i++) {
        let file = this.image[i];

        formData.append("images[" + i + "]", file);
      }

      for (var pair of formData.entries()) {
        // console.log(pair[0] + ", " + pair[1]);
      }

      axios
        .post("product/group_product_insert", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          this.$router.push("/dashboard/pages/groupproduct");
        });
    },

    saveCategory() {
      this.category.push(this.new_category);
      this.dialog1 = false;
    },

    addSubCategory() {
      this.sub_category.push(this.new_sub_category);
      this.dialog2 = false;
    },

    addSubSubCategory() {
      this.sub_sub_category.push(this.new_sub_sub_category);
      this.dialog3 = false;
    },
    close() {
      // this.$router.push("/dashboard/pages/product");
    },

    saveEdit() {
      axios
        .post("category/insert/", {
          category: this.category,
          sub_category: this.sub_category,
          sub_sub_category: this.sub_sub_category,
        })
        .then((response) => {
    
  
          this.dialog1 = false;
          this.dialog2 = false;
          this.dialog3 = false;
        });
    },

    getAllCastegory() {
      axios.get("category/categories/").then((response) => {
        this.category = response.data;
      });
    },

    onSelectCategory() {
      axios
        .post(`category/subcategories/`, { name: this.selectedCatyegory })
        .then((response) => {
          this.sub_category = response.data;
        });
    },

    onSelectSubCategory() {
      axios
        .post(`category/subsubcategories/`, { name: this.selectedSubCatyegory })
        .then((response) => {
          this.sub_sub_category = response.data;
        });
    },
  },
  mounted() {
    this.getAllCastegory();
  },
};
</script>

<style>
</style>